
import {mapGetters} from 'vuex';
import ProviderList from '~/components/external-integrations/ProviderList.vue'
import IconLogoAeqNoir from "~/components/icons/IconLogoAeqNoir.vue";
import ProviderDetailed from "~/components/external-integrations/ProviderDetailed.vue";

export default {
  name: `IntegrationsPage`,
  components: {ProviderDetailed, IconLogoAeqNoir, ProviderList},
  data() {
    return {
      askMoreDetailsProcessing: false
    }
  },
  computed: {
    ...mapGetters({
      selectedProvider: `integrations/selectedProvider`
    })
  },
  methods: {
    mounted() {
      this.$store.commit(`integrations/setSelectedProvider`, undefined);
    },
    askMoreDetails() {
      this.askMoreDetailsProcessing = true
      this.$api.integrations.askMoreDetailsAPI()
        .then((res) => {
          const fileName = res.headers.get(`Content-Filename`)
          this.$helpers.FileSaver.saveAs(res._data, fileName)
          this.$flashMessage.success({
            title: this.$t(`messages.success`),
            message: this.$t(`messages.message_sent`)
          })
        })
        .catch((error) => {
          console.log(error)
          this.$flashMessage.error({
            title: this.$t(`messages.error`),
            message: this.$t(`messages.unknown_error`)
          })
        })
        .finally(() => {
          this.askMoreDetailsProcessing = false
        })
    }
  }
}
